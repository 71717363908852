import { Toaster } from "@/components/ui/sonner";
import { themeSessionResolver } from "@/lib/theme/sessions.server";
import "@/tailwind.css";
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import NProgress from "nprogress";
import { memo, useEffect } from "react";
import { useMountedState } from "react-use";
import {
  PreventFlashOnWrongTheme,
  ThemeProvider,
  useTheme,
} from "remix-themes";
import { ErrorMessageBoundary } from "./components/error";

export const meta: MetaFunction = () => {
  return [
    { title: "Miura Seller" },
    { name: "description", content: "Miura Seller" },
  ];
};

export const loader: LoaderFunction = async ({ request }) => {
  const { getTheme } = await themeSessionResolver(request);
  return {
    theme: getTheme(),
  };
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/api/set-theme">
      <App />
    </ThemeProvider>
  );
}

const AppExport =
  process.env.NODE_ENV === "production"
    ? withSentry(AppWithProviders)
    : AppWithProviders;

export default AppExport;

const NProgressHook = () => {
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === "loading") {
      NProgress.start();
    } else {
      NProgress.done(true);
    }
  }, [navigation.state]);
  return null;
};

const BodyMemo = memo(function BodyMemo() {
  return (
    <>
      <Toaster position="top-center" />
      <Outlet />
      <ScrollRestoration />
      <NProgressHook />
      <Scripts />
    </>
  );
});

function ThemeColor() {
  const [theme] = useTheme();
  const mounted = useMountedState();
  if (!mounted()) return null;
  return (
    <meta
      name="theme-color"
      content={theme === "dark" ? "#09090b" : "#ece3d4"}
    />
  );
}

export function App() {
  const data = useLoaderData<typeof loader>();
  const [theme] = useTheme();
  return (
    <html lang="es" className={theme ?? ""}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <ThemeColor />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
        <Meta />
        <Links />
      </head>
      <body className="bg-background font-sans antialiased">
        <BodyMemo />
      </body>
    </html>
  );
}

export const shouldRevalidate = () => false;

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <html lang="es" className="dark">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-background font-sans antialiased">
        <ScrollRestoration />
        <Scripts />
        <ErrorMessageBoundary />
      </body>
    </html>
  );
}
